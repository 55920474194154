import * as signalR from "@microsoft/signalr";

import Player from '../models/player';
import Position from '../models/position';

/**
 * This class takes care of websocket communication with the server. It should
 * take care of serialization concerns, but should not concern itself with game logic.
 * @class
 */
class Hub {
    constructor(game) {
        this.game = game;

        const signalRUrl = process.env.REACT_APP_SIGNALR_URL;
        console.log(`SignalR URL: ${signalRUrl}`);

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(signalRUrl) // Update with your server URL
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this.hubConnection.onclose(() => {
            console.log("Disconnected from SignalR hub");
            // TODO: reconnection logic
        });

        this.hubConnection.on('Ping', (serverTicks, pingMs) => {
            this.hubConnection.invoke('Pong', serverTicks);
            this.game.updatePing(pingMs);
        });

        this.hubConnection.on("PlayerRegistered", (playerData) => {
            const player = this.makePlayer(playerData);
            this.game.playerRegistered(player);
        });

        this.hubConnection.on("PlayerList", (playerData) => {
            const players = [];
            for (let item of playerData) {
                players.push(this.makePlayer(item));
            }
            this.game.updatePlayerList(players);
        });

        this.hubConnection.on('PlayerJoined', (playerData) => {
            const player = this.makePlayer(playerData);
            this.game.playerJoined(player);
        });

        this.hubConnection.on('UpdatePlayerPosition', (connectionId, positionData) => {
            //console.log(`Position update from: ${connectionId}`);
            const position = this.makePosition(positionData);
            this.game.updatePlayerPosition(connectionId, position);
        });

        this.hubConnection.on('ChatMessage', (message) => {
            this.game.addChatMessage(message);
        });

        this.hubConnection.on('BulletFired', (position) => {
            const p = this.makePosition(position);
            this.game.bulletFired(position);
        });
    }

    makePosition(data) {
        return new Position(data.x, data.y, data.angle, data.angularVelocity, data.speed);
    }

    makePlayer(data) {
        const postition = this.makePosition(data.lastPosition);
        const player = new Player(data.playerName, data.connectionId, data.colorCode, postition);
        return player;
    }

    async createBullet(position) {
        await this.hubConnection.invoke('CreateBullet', position);
    }

    async connect() {
        try {
            console.log('Connecting...');
            await this.hubConnection.start();
            console.log('Connection successful!');
        } catch (err) {
            console.error('Connection failed: ', err);
        }
    }
    

    async registerPlayer(playerName) {
        try {
            await this.hubConnection.invoke('RegisterPlayer', playerName);
            console.log('Registration successful!');
        } catch (err) {
            console.error('Registration failed: ', err);
        }
    }

    async sendPlayerPosition(position) {
        try {
            await this.hubConnection.invoke('UpdatePlayerPosition', position);
        } catch (err) {
            console.error('Error sending position: ', err);
        }
    }

    async sendChatMessage(message) {
        try {
            await this.hubConnection.invoke('SendChatMessage', message);
        } catch (err) {
            console.error('Error sending position: ', err);
        }
    }
}

export default Hub;
/**
 * This class represents the position and velocity of a game object
 */
class Position {
    constructor(x=400, y=300, angle=0, angularVelocity=0, speed=0) {
        this.x = x;
        this.y = y;
        this.angle = angle;
        this.angularVelocity = angularVelocity;
        this.speed = speed;
    }
}

export default Position;
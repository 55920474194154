
/**
 * Represents a player in the game.
 * @class
 */
class Player {
    constructor(playerName, connectionId, colorCode, lastPosition) {
        this.playerName = playerName;
        this.connectionId = connectionId;
        this.colorCode = colorCode;
        this.lastPosition = lastPosition;
        this.tank = null;
    }
}

export default Player;
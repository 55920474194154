import Phaser from 'phaser';

import Game from '../game';

/**
 * This class implements the main playing field of the game. It takes care of the rendering and
 * physics of the tanks.
 * @class
 * @extends Phaser.Scene
 */
class MainScene extends Phaser.Scene {
    constructor() {
        super('MainScene');
        this.chatPanelHeight = 100;
        this.lastBulletFired = 0;
    }

    preload() {
    }

    init(data) {
        // Do something with data.playerName
    }

    /**
     * Called by Phaser when the scene is created
     */
    create() {
        // Display ping text
        this.latencyText = this.add.text(
            Game.config.width - 150, 
            30, 
            'Ping: waiting...', 
            { font: '16px Arial', fill: '#ffffff' }
        ).setOrigin(1, 1);
        this.createPlayerPanel();
        this.createChatPanel();

        // Create cursor keys for input
        this.cursors = this.input.keyboard.createCursorKeys();

        this.input.keyboard.on('keydown-T', (event) => {
            if (document.activeElement.id !== 'chatInput') {
                event.preventDefault(); // Stop 'T' from being added to the input
                this.toggleChatInput();
            }
        });
        
        let chatInput = document.getElementById('chatInput');
        chatInput.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                this.handleChatMessage(chatInput.value);
                chatInput.value = '';
                chatInput.style.display = 'none';
                this.game.canvas.focus(); // Refocus on the game canvas
            } else if (event.key === 'Escape') {
                chatInput.value = '';
                chatInput.style.display = 'none';
                this.game.canvas.focus(); // Refocus on the game canvas
            } else if (event.key === ' ') {
                // Allow spacebar for spaces in the message
                event.preventDefault();
                chatInput.value += ' ';
            }
        });
    }

    createPlayerPanel() {
        let playerPanel = this.add.graphics();
        playerPanel.fillStyle(0x000000, 0.5); // Semi-transparent black
        playerPanel.fillRect(0, 0, 200, Game.config.height - 100);
        this.playerText = this.add.text(100, 10, 'Players:', { fontSize: '16px', fill: '#fff' }).setOrigin(0.5, 0);
        playerPanel.setDepth(100);
        this.playerText.setDepth(101);
    }

    createChatPanel() {
        let chatPanel = this.add.graphics();
        chatPanel.fillStyle(0x000000, 0.5); // Semi-transparent black
        chatPanel.fillRect(0, Game.config.height - this.chatPanelHeight, Game.config.width, this.chatPanelHeight);
        this.chatText = this.add.text(10, Game.config.height - 90, '', { fontSize: '16px', fill: '#fff' });
        chatPanel.setDepth(100);
        this.chatText.setDepth(101);
    }

    updatePlayerList(players) {
        console.log(`Updating player list with ${players.length} players: ${JSON.stringify(players)}`);
        let text = 'Players:\n';
        for (let player of players) {
            text += `${player.playerName}\n`;
        }
        this.playerText.setText(text);
    }
    
    addChatMessage(message) {
        let existingText = this.chatText.text;
        let newText = existingText + '\n' + message;
        this.chatText.setText(newText);
        this.trimChatMessages();
    }

    trimChatMessages() {
        let chatTextHeight = this.chatText.height;
        while (chatTextHeight > this.chatPanelHeight && this.chatText.text.includes('\n')) {
            this.chatText.setText(this.chatText.text.substring(this.chatText.text.indexOf('\n') + 1));
            chatTextHeight = this.chatText.height;
        }
    }

    toggleChatInput() {
        let chatInput = document.getElementById('chatInput');
        if (chatInput.style.display === 'none') {
            chatInput.style.display = 'block';
            chatInput.focus();
        } else {
            chatInput.style.display = 'none';
            this.game.canvas.focus(); // Refocus on the game canvas
        }
    }

    handleChatMessage(message) {
        if (message.trim() !== '') {
            console.log('Chat message:', message);
            Game.game.sendChatMessage(message);
        }
    }

    createTank(player) {
        // Create a rectangle to represent the tank
        const tankColor = parseInt(player.colorCode, 16);
        console.log(`Creating tank for player ${player.playerName} with color ${tankColor} and connectionId ${player.connectionId}`);
        const tank = this.add.rectangle(player.lastPosition.x, player.lastPosition.y, 60, 40, tankColor);

        // Enable physics for the tank
        this.physics.add.existing(tank);

        // Set maximum velocity
        
        tank.body.setMaxVelocity(200, 200);
        tank.setDepth(0);

        player.tank = tank;

        return tank;
    }

    removeTank(player) {
        if (!player.tank) return;
        this.physics.world.remove(player.tank);
        player.tank.destroy();
        player.tank = null;
    }

    /**
     * Updates method that's called once per frame
     */
    update() {
        if (!Game.game.localPlayer) return;
        const tank = Game.game.localPlayer.tank;
        if (!tank) return;

        // Reset tank velocity
        tank.body.setVelocity(0);

        // Rotation controls
        if (this.cursors.left.isDown) {
            tank.body.setAngularVelocity(-150);
        } else if (this.cursors.right.isDown) {
            tank.body.setAngularVelocity(150);
        } else {
            tank.body.setAngularVelocity(0);
        }
        
        this.input.keyboard.on('keyup-R', (event) => {
            if (Date.now()-this.lastBulletFired >= 1000)
            {
                console.log("bullet");
                Game.game.createBulletServer();
                this.lastBulletFired = Date.now();
            }
        });

        // Forward/back controls
        if (this.cursors.up.isDown) {
            this.physics.velocityFromRotation(tank.rotation, 200, tank.body.velocity);
        } else if (this.cursors.down.isDown) {
            this.physics.velocityFromRotation(tank.rotation, -200, tank.body.velocity);
        }

        // Update the official position
        const position = Game.game.localPlayer.lastPosition;
        position.x = tank.x;
        position.y = tank.y;
        position.angle = tank.rotation;
        position.angularVelocity = tank.body.angularVelocity;
        position.speed = tank.body.speed;
    }

    createBullet(position)
    {
        var bullet = this.add.rectangle(position.x, position.y, 50, 10, parseInt(0x000000, 16));
        this.physics.add.existing(bullet);
        //bullet.body.maxVelocity = 500;
        bullet.rotation = position.angle;
        bullet.body.rotation = position.angle;
        this.physics.velocityFromRotation(bullet.rotation, 500, bullet.body.velocity);
        bullet.name = "bullet"+Game.game.localPlayer.connectionId;
        setTimeout(() => {
            this.physics.world.remove(bullet);
            bullet.destroy();
            bullet = null;
        }, 3000);
    }

    updatePing(pingMs) {
        if (this.latencyText) {
            this.latencyText.setText(`Ping: ${pingMs} ms`);
        }
    }    
    
    updateTankPosition(player) {
        const tank = player.tank;
        if (!tank) {
            console.log(`Tank not found for player ${player.playerName}`);
            return;
        }
        const position = player.lastPosition;
        tank.x = position.x;
        tank.y = position.y;
        tank.rotation = position.angle;
        tank.body.angularVelocity = position.angularVelocity;
        tank.body.speed = position.speed;
        //console.log(`Tank position updated for player ${player.playerName}`);
    }
}

export default MainScene;
import Hub from './signalr/hub';
import MainScene from './scenes/mainScene';
import JoinScene from './scenes/joinScene';

import Phaser from 'phaser';

/**
 * This is the main logic class for the game, and keeps track of the state of all players.
 * It should not concern itself with graphics or server communication.
 * @class
 */
class Game {
    static config = {
        type: Phaser.AUTO,
        width: 1000,
        height: 700,
        physics: {
            default: 'arcade',
            arcade: {
                debug: true
            }
        },
        backgroundColor: '#A52A2A',
        scene: [JoinScene, MainScene]
    };

    static game = null;

    constructor() {
        Game.game = this;
        this.hub = new Hub(this);
        console.log('Hub created');

        this.phaserGame = new Phaser.Game(Game.config);
        this.localPlayer = null;
        this.otherPlayers = {};        

        setInterval(() => {
            this.sendPlayerPosition();
        }, 100);
    }

    joinGame(playerName) {
        console.log(`Joining game as ${playerName}`);
        this.phaserGame.scene.stop('JoinScene');
        this.phaserGame.scene.start('MainScene');
        this.mainScene = this.phaserGame.scene.getScene('MainScene');
        this.hub.connect().then(() => {
            this.hub.registerPlayer(playerName);
        });
    }

    updatePing(pingMs) {
        console.log(`Ping: ${pingMs} ms`);
        this.mainScene.updatePing(pingMs);
    }

    playerRegistered(player) {
        this.localPlayer = player;
        this.mainScene.createTank(player);
        
        console.log(`Player registered: ${JSON.stringify(player)}`);
    }

    updatePlayerList(players) {
        // Add players from server that don't existing locally
        for (let otherPlayer of players) {
            if (otherPlayer.connectionId === this.localPlayer.connectionId) continue;
            if (!this.otherPlayers[otherPlayer.connectionId]) {
                this.addPlayer(otherPlayer);
            }
            
        }

        // Remove players that exist locally but not on the server
        for (let connectionId in this.otherPlayers) {
            if (this.otherPlayers.hasOwnProperty(connectionId)) {
                let found = false;
                for (let otherPlayer of players) {
                    if (otherPlayer.connectionId === connectionId) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    this.removePlayer(this.otherPlayers[connectionId]);                    
                }
            }
        }

        this.mainScene.updatePlayerList([...Object.values(this.otherPlayers), this.localPlayer]);

        this.logKnownPlayers();
    }

    createBulletServer()
    {
        this.hub.createBullet(this.localPlayer.lastPosition);
    }

    bulletFired(position)
    {
        this.mainScene.createBullet(position);
    }

    addPlayer(player) {
        this.otherPlayers[player.connectionId] = player;
        console.log(`Adding player ${player.connectionId}`);
        this.mainScene.createTank(player);
    }

    removePlayer(player) {
        delete this.otherPlayers[player.connectionId];
        console.log(`Removing player ${player.connectionId}`);
        this.mainScene.addChatMessage(`${player.playerName} left the game.`);
        this.mainScene.removeTank(player);
    }

    playerJoined(player) {
        console.log(`Player joined: ${player.connectionId}`);
        this.mainScene.addChatMessage(`${player.playerName} joined the game.`);
        this.logKnownPlayers();
    }

    updatePlayerPosition(connectionId, position) {
        const player = this.otherPlayers[connectionId];
        if (player) {
            //console.log(`Position update from: ${connectionId} -- ${player.playerName}`);
            player.lastPosition = position;
            this.mainScene.updateTankPosition(player);
        } else {
            console.log(`Position update from unknown player: ${connectionId}`);
        }        
    }

    sendPlayerPosition() {
        if (this.localPlayer) {
            this.hub.sendPlayerPosition(this.localPlayer.lastPosition);
        }

        //this.logKnownPlayers();
    }

    logKnownPlayers() {
        let players = '';
        for (let connectionId in this.otherPlayers) {
            if (this.otherPlayers.hasOwnProperty(connectionId)) {
                //players += `${connectionId} : ${JSON.stringify(this.otherPlayers[connectionId])}\n`;
                players += `${connectionId}\n`;
            }
        }
        console.log(`Known players: ${players}`);
    }

    sendChatMessage(message) {
        this.hub.sendChatMessage(message);
    }

    addChatMessage(message) {
        this.mainScene.addChatMessage(message);
    }
}

export default Game;
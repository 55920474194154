import Phaser from 'phaser';
import Game from '../game';
class JoinScene extends Phaser.Scene {
    constructor() {
        super({ key: 'JoinScene' });
    }

    create() {
        this.playerName = '';

        this.add.text(400, 200, 'Enter Your Name:', { fontSize: '32px' }).setOrigin(0.5);

        this.nameInput = this.add.text(400, 250, '', { fontSize: '28px', backgroundColor: '#0000ff' })
            .setOrigin(0.5)
            .setPadding(10)
            .setInteractive(new Phaser.Geom.Rectangle(0, 0, 300, 50), Phaser.Geom.Rectangle.Contains)
            .on('pointerdown', () => this.activateInput());

        this.startButton = this.add.text(400, 350, 'Join Game', { fontSize: '24px', backgroundColor: '#ffffff', color: '#000000' })
            .setOrigin(0.5)
            .setInteractive()
            .on('pointerdown', () => this.startGame());

        this.input.keyboard.on('keydown', (event) => {
            if (this.isActiveInput) {
                if (event.keyCode === 8 && this.playerName.length > 0) { // Backspace key
                    this.playerName = this.playerName.substr(0, this.playerName.length - 1);
                } else if (event.keyCode === 13) { // Enter key
                    this.startGame();
                } else if (event.keyCode >= 48 && event.keyCode <= 90) { // Alphanumeric keys
                    this.playerName += event.key;
                }
                this.nameInput.setText(this.playerName);
            }
        });
    }

    activateInput() {
        this.isActiveInput = true;
    }

    startGame() {
        if (this.playerName.trim().length > 0) {
            Game.game.joinGame(this.playerName);
        }
    }    
}

export default JoinScene;